<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-end w-250">
            <div class="language-container">
              <b-dropdown :text="selectedLanguage.name">
                <b-dropdown-item
                  v-for="lang in languages"
                  :key="lang.code"
                  @click="onLanguageSelect(lang)"
                >
                  {{ lang.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <DxTreeList
          id="tasks"
          :data-source="mainDataSource"
          :show-borders="true"
          :column-auto-width="true"
          :word-wrap-enabled="true"
          :expanded-row-keys="expandedRows"
          key-expr="id"
          parent-id-expr="chief_id"
        >
          <DxColumn
            data-field="username"
            :caption="this.$t('hierarchy.username')"
          />
          <DxColumn
            data-field="firstname"
            :caption="this.$t('hierarchy.firstname')"
          />
          <DxColumn
            data-field="lastname"
            :caption="this.$t('hierarchy.lastname')"
          />
        </DxTreeList>
      </div>
    </div>
    <b-modal
      ref="import-modal"
      centered
      title="Import"
      ok-only
      :ok-title="$t('buttons.ok')"
    >
      <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
      <b-card-text v-if="importResult.length !== 0">
        {{ importResult }}
      </b-card-text>
      <div class="d-flex justify-content-center">
        <b-spinner v-if="importResult.length === 0" />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from '@axios';
import {
  BCard,
  BDropdown,
  BDropdownItem,
  BModal,
  BCardText,
  BSpinner
} from 'bootstrap-vue';


import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {DxTreeList, DxColumn} from 'devextreme-vue/tree-list'
import 'jspdf-autotable';

export default {
  components: {
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BModal,
    BSpinner,
    BCard,
    DxTreeList,
    DxColumn
  },
  data() {
    return {
      mainDataSource : [],
      expandedRows : [],
      can_edit : false,
      can_list : false,
      can_create : false,
      can_delete : false,
      dataUnit: [],
      dataCurrency: [],
      databoq: [],
      languages: [],
      selectedLanguage: {},
      dataLoading: true,
      error: '',
      file: null,
      importResult: 'deneme'
    };
  },
  async mounted() {
    await this.getPageAuths();
    await this.getLanguage();
    await this.getUnit();

  },
  methods: {
    async getPageAuths(){
      const pageAuths = await axios.post('/get-page-auth', { page: 'BOQ' })
      this.can_edit = pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = pageAuths.data.can_delete;
      this.can_create = pageAuths.data.can_create;
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
          (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';


      try {
        this.mainDataSource = (await axios.get('/hierarchy')).data;
        if(this.mainDataSource.length > 0){
          this.mainDataSource[0].chief_id = null;
          this.mainDataSource.forEach(a=>{
            this.expandedRows.push(a.id)
          })
        }


        this.dataLoading = false;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async refreshList() {
      this.dataLoading = true;
      await this.getLanguage();
      await this.getUnit();
    },
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
    },
    handleImport() {
      if (!this.file) {
        this.showToast({
          variant: 'danger',
          title: this.$t('modal.error'),
          text: this.$t('modal.please-choose-file-first')
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.importResult = '';
      this.$refs['import-modal'].show();
      this.showToast({
        variant: 'info',
        icon: 'InfoIcon',
        text: this.$t('modal.start-import')
      });
      axios
        .post(`import-boq`, formData)
        .then((response) => {
          if (response.status === 200) {
            this.importResult = this.$t('general.importSuccess');
            this.showToast({
              variant: 'success',
              text: this.importResult
            });
            this.refreshList();
          } else {
            this.importResult = this.$t('general.importError');
            this.showToast({
              variant: 'danger',
              text: this.importResult
            });
          }
        })
        .catch((err) => {
          this.importResult = err?.response?.data || err?.message;
          this.showToast({
            variant: 'danger',
            text: this.importResult
          });
        });
    },
  }
};
</script>


<style lang="scss">
@import './tables.scss';
.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>
